<script>
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import TableButton from '@/components/TableButton'
import DrawerForm from '@/components/DrawerForm'
import apiTool from '@/command/apiTool'

export default {
  name: 'iot',
  data() {
    return {
      dataList: [
        {
          id: '1',
          serial: '1',
          mainName: '湿度感应器',
          keys: '慈溪市有利农场',
          opener: 'SPDLHSIW89',
          ips: 'A级',
          time: '5分钟',
          frequency: '1',
          Way: '1',
          status: '2021-2-12 08:25:01',
          log: '日志',
          a1: '详情',
        },
        {
          id: '2',
          serial: '2',
          mainName: '温度感应器',
          keys: '慈溪市华农农场',
          opener: 'HFHFKSIW89',
          ips: 'B级',
          time: '2小时',
          frequency: '0',
          Way: '0',
          status: '2021-1-12 08:05:11',
          log: '日志',
          a1: '详情',
        },
      ],
    }
  },
  computed: {},
  mounted() {},
  methods: {
    getHeader() {
      return [
        {
          name: '设备名称',
          type: 'input',
          key: '',
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加设备',
          type: 'primary',
          onClick: () => {
            this.$router.push('/iot/detail')
          },
        },
        // {
        //   name: '手动采集 (导出excel)',
        //   onClick: (data) => {
        //     let dataT = getParams(data)
        //     // downloadAction(`/api/dataoperation/collectionMainBody/mainBodyExport${dataT}`, '主体列表文件.xlsx')
        //   },
        // },
      ]
    },
    getColumns() {
      return [
        {
          title: '序号',
          dataIndex: 'serial',
          align: 'left',
        },
        {
          title: '物联网设备名称',
          dataIndex: 'mainName',
          align: 'left',
        },
        {
          title: '设备归属',
          dataIndex: 'keys',
          align: 'left',
        },
        {
          title: '型号规格',
          dataIndex: 'opener',
          align: 'left',
        },
        {
          title: '设备等级',
          dataIndex: 'ips',
          align: 'left',
        },
        {
          title: '收发频率',
          dataIndex: 'time',
          align: 'left',
        },
        {
          title: '限定状态',
          dataIndex: 'frequency',
          align: 'left',
          filters: [
            {
              text: '超标',
              value: '0',
            },
            {
              text: '正常',
              value: '1',
            },
          ],
          render: ({ text }) => {
            return text == '0' ? (
              <span style={{ color: '#5fce60' }}>正常</span>
            ) : (
              <span style={{ color: '#de350c' }}>超标</span>
            )
          },
        },
        {
          title: '连接状态',
          dataIndex: 'Way',
          align: 'left',
          filters: [
            {
              text: '正常',
              value: '0',
            },
            {
              text: '断开',
              value: '1',
            },
          ],
          render: ({ text }) => {
            return text == '0' ? (
              <span style={{ color: '#5fce60' }}>正常</span>
            ) : (
              <span style={{ color: '#de350c' }}>断开</span>
            )
          },
        },
        {
          title: '创建时间',
          dataIndex: 'status',
          align: 'left',
        },
        {
          title: '操作',
          dataIndex: 'a1',
          render: ({ records }) => {
            const data = [
              {
                name: '设备属性',
                display: true,
                onClick: (records) => {
                  this.handleAddData(records)
                },
              },
              {
                name: '编辑',
                display: true,
                onClick: (records) => {
                  this.$router.push(`/iot/detail?id=${records.id}`)
                },
              },
              {
                name: '详情',
                display: true,
                onClick: (records) => {
                  this.$router.push(`/iot/detail?id=${records.id}`)
                },
              },
              {
                name: '删除',
                display: true,
                onClick: (records) => {},
              },
            ]
            return <TableButton data={data} />
          },
        },
      ]
    },
    getDrawerForm() {
      // const form = [
      //   {
      //     form: [
      //       {
      //         name: '电话名称',
      //         type: 'input',
      //         key: 'name',
      //         rules: [
      //           {
      //             required: true,
      //             type: 'string',
      //           },
      //         ],
      //       },
      //       {
      //         name: '电话号码',
      //         type: 'input',
      //         key: 'hotlineNumber',
      //         rules: [
      //           {
      //             required: true,
      //             type: 'string',
      //           },
      //         ],
      //       },
      //       {
      //         name: '排序序号',
      //         type: 'inputNumber',
      //         key: 'sort',
      //         props: {
      //           min: 1,
      //           max: 1000,
      //           placeholder: '排序序号（1-1000，数值越小，越靠前）',
      //         },
      //       },
      //       {
      //         name: '电话状态',
      //         type: 'switch',
      //         key: 'status',
      //       },
      //     ],
      //   },
      // ]
      // return form
    },
    handleAddData(records) {
      let data = this.getDrawerForm(records)
      apiTool.showDrawer({
        title: '设备属性',
        width: '600px',
        view: DrawerForm,
        viewProps: {
          data,
          form: records,
        },
        success: ({ data, setHidden }) => {
          // if (data.id) {
          //   putAction('/api/psServiceHotline', {
          //     ...data,
          //   }).then((res) => {
          //     if (res.code == 200) {
          //       this.$message.success('编辑成功！')
          //       setHidden()
          //     } else {
          //       this.$message.error(res.msg)
          //     }
          //   })
          // } else {
          //   console.log(data)
          //   postAction('/api/psServiceHotline', {
          //     ...data,
          //   }).then((res) => {
          //     if (res.code == 200) {
          //       this.$message.success('添加成功！')
          //       this.refreshList()
          //       setHidden()
          //     } else {
          //       this.$message.error(res.msg)
          //     }
          //   })
          // }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        // url={'/api/dataoperation/collectionMainBody/page'}
        data={this.dataList}
        tableColumns={this.getColumns()}
        headerData={this.getHeader()}
        button={this.getButton()}
        ref={'table'}
      />
    )
  },
}
</script>
